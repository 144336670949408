import katex from 'katex';
import 'katex/dist/katex.css';

const theme_storage_key = 'make_my_eyes_bleed';
let force_theme = false;
window['inverted'] = false;
window.addEventListener('load', init);
preInit();

function setInvert(inverted: boolean, save: boolean = false) {
    if (!force_theme || save) {
        window['inverted'] = inverted;
        if (inverted) {
            document.documentElement.classList.add('--inverted');
        } else {
            document.documentElement.classList.remove('--inverted');
        }
    }
    if (save) {
        force_theme = true;
        localStorage.setItem(theme_storage_key, inverted ? '1' : '0');
    }
}

function preInit() {
    const saved = localStorage.getItem(theme_storage_key);
    if (saved == '1') {
        setInvert(true, true);
    } else if (saved == '0') {
        setInvert(false, true);
    }

    const prefers_light = window.matchMedia('(prefers-color-scheme: light)');
    setInvert(prefers_light.matches);
    prefers_light.addEventListener('change', m => setInvert(m.matches));

    document.fonts.addEventListener('loadingdone', function (event) {
        for (const face of this) {
            if (face.family.includes('NerdFontsSymbols')) {
                document.body.classList.remove('--noicons');
            }
        }
    });
    //document.head.innerHTML += '<link rel="stylesheet" href="https://www.nerdfonts.com/assets/css/webfont.css">';
}

const a_lower = 'a'.charCodeAt(0);
const a_upper = 'A'.charCodeAt(0);

function rot13(x: string) {
    let y = ''
    for (let c of x.split('')) {
        let i = c.charCodeAt(0);
        if (a_lower <= i && i <= a_lower + 26) {
            i = (i - a_lower + 13) % 26 + a_lower;
        } else if (a_upper <= i && i <= a_upper + 26) {
            i = (i - a_upper + 13) % 26 + a_upper;
        }
        y += String.fromCharCode(i);
    }
    return y;
}

function nospamOff(this: HTMLElement, event: Event) {
    this.removeEventListener('click', nospamOff);
    this.removeEventListener('mouseover', nospamOff);
    let href = this.attributes.getNamedItem('href');
    if (href != null) {
        href.value = rot13(href.value);
    }
}

function init() {
    const toggle = document.getElementById('darkness-toggle');
    if (toggle) {
        toggle.addEventListener('click', () => setInvert(!window['inverted'], true));
    }

    const math_elements = Array.from(document.getElementsByClassName('math'));
    for (const elem of math_elements) {
        const source = elem.textContent;
        elem.textContent = '';
        try {
            katex.render(source, elem, {
                displayMode: elem.tagName === 'DIV',
            });
        } catch (error) {
            elem.textContent = source;
            console.error(error);
        }
    }

    const nospam_elements = Array.from(document.getElementsByClassName('nospam'));
    for (const elem of nospam_elements) {
        elem.addEventListener('mouseover', nospamOff);
        elem.addEventListener('click', nospamOff);
    }
}
